@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Bebas Neue', cursive;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

* {
  padding: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(1em + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* The sidebar menu */
.sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  /* background-color: #111; Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 3rem; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  /* background-image: linear-gradient(to top, #30cfd0 -50%, #330867 100%); */
  background-color: rgb(4, 0, 37);
}

.myHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 10;
  /* border: 0.1rem solid blue; */
  /* background-color: black; */
  color: white;
  /* background-image: linear-gradient(to top, #30cfd0 -110%, #330867 100%); */
  background-color: rgb(4, 0, 37);
}

/* The sidebar links */
.sidebar a {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  text-decoration: none;
  font-size: 3rem;
  color: white;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
  color: #f1f1f1;
  font-size: 4.5rem;
}

.sidebar #navlinks div:hover {
  color: #f1f1f1;
  font-size: 4.5rem;
}

.overlay-content-jp div:hover {
  color: #f1f1f1;
  font-size: 4.5rem;
}

.sidebar #navlinks div {
  color: #f1f1f1;
  font-size: 4.5rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  text-decoration: none;
  font-size: 3rem;
  color: white;
  display: block;
  transition: 0.3s;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  position: absolute;
  top: 0.75rem;
  right: 1.25rem;
  font-size: 2rem;
  margin-left: 1rem;
  background-color: transparent;
  border: transparent;
  color: white;
}

/* .overlay-jp .closebtn {
  position: absolute;
  top: 0.75rem;
  right: 1.25rem;
  font-size: 4rem;
  margin-left: 1rem;
  background-color: transparent;
  border: transparent;
  color: white;
} */

header h1 {
  margin: 0.25em 0;
  color: white;
  font-size: 2.5rem;
}
h1 {
  /* color: darkslateblue; */
  color: rgb(4, 0, 37);
}
/* The button used to open the sidebar */
.openbtn {
  font-size: 3rem;
  cursor: pointer;
  background-color: black;
  color: white;
  padding: 1rem 1rem;
  border: none;
}

.overlayopenbtn {
  font-size: 3rem;
  cursor: pointer;
  background-color: black;
  color: white;
  padding: 1rem 1rem 0 0;
  border: none;
}

#myOpenBtn {
  position: absolute;
  top: 0;
  right: 0;
  margin: -1.15rem 0 0 0;
  background: transparent;
  /* -webkit-appearance: none; */
}

#myCloseBtn {
  position: absolute;
  top: 0;
  right: 0.5rem;
  font-size: 5rem;
  margin-left: 1rem;
  margin-top: -0.15em;
  background-color: transparent;
  border: transparent;
  color: white;
}

#myOverlayOpenBtn {
  position: absolute;
  /* top: 0; */
  right: 0;
  margin: -0.6em 0 0 0;
  background: transparent;
  border: transparent;
  color: white;
  /* -webkit-appearance: none; */
}

.overlay-jp {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: 0.5s;
  background-color: rgb(4, 0, 37);
}

.overlay-content-jp {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay-jp div {
  padding: 8px;
  text-decoration: none;
  font-size: 3rem;
  color: white;
  display: block;
  transition: 0.3s;
}

/* .overlay-jp div:hover,
.overlay-jp div:focus {
  color: black;
} */

.overlay-jp .closebtn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 5rem;
}

@media screen and (max-height: 450px) {
  .overlay-jp div {
    font-size: 2rem;
  }
  .overlay-jp .closebtn {
    font-size: 4rem;
    top: 1rem;
    right: 1rem;
  }
}

.openbtn:hover {
  background-color: #444;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s; /* If you want a transition effect */
  /* padding: 0.2rem; */
  background-color: white;
  /* height: 89vh; */
  padding: 0 0 1em 0;
}
.about p {
  text-align: left;
  text-align: initial;
  padding: 1em 2em;
  margin: 0;
}
.projectCard {
  /* border: 0.1rem solid black; */
  padding: 1rem;
  margin: 1.5rem auto;
  border-radius: 1rem;
  max-width: 80vw;
  background-color: white;
  /* box-shadow: 0rem 0rem 1rem 0.1rem darkslateblue; */
}

.projectCardB {
  /* border: 0.1rem solid black; */
  padding: 1rem;
  margin: 1.5rem auto;
  border-radius: 1rem;
  max-width: 80vw;
  background-color: white;
  border-radius: 41px;
  background: #ebe5e5;
  box-shadow: 33px 33px 65px #c1bcbc, -33px -33px 65px #ffffff;
}

.projectCardC {
  /* border: 0.1rem solid black; */
  padding: 0 0 0.25rem 0;
  margin: 0.5rem auto;
  border-radius: 0.5rem;
  max-width: 80vw;
  max-height: 900px;
  /* width: 77vw; */
  background-color: white;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.projectCard a {
  font-size: 2rem;
}

.projectCardC p {
  margin: 0.5em;
  padding: 0 1em;
  /* text-align: initial; */
}

.projectCardC img {
  max-height: 60%;
  /* text-align: initial; */
}

.projectCardD {
  /* border: 0.1rem solid black; */
  width: 40vw;
  /* height: 40vw; */

  padding: 0.5rem;
  /* margin: 1.5rem auto; */
  border-radius: 0rem;
  max-width: 40vw;
  background-color: white;
  /* box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3); */
}

.projectCardE {
  display: -webkit-flex;
  display: flex;
  padding: 0.5rem;
}

.projectCardE * {
  position: absolute;
  top: 0;
  left: 0;
}

.projectCardE img {
  position: relative;
}

article div .projects {
  /* display: grid; */
  grid-template-columns: 1fr 1fr;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.newpc .hidden * {
  display: none;
}
.hidden {
  display: none;
}

.is-selected .hidden {
  display: block;
  background-color: rgb(4, 0, 37);
}

.is-selected .hidden * {
  display: block;
}

.pageDiv {
  /* border: 10px solid black; */
  padding: 10px;
  margin: 5px;
  background-color: rgb(4, 0, 37);
  color: white;
  text-align: center;
  width: 36%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 0.1em;
}

.pageDiv:hover {
  box-shadow: 0.1em 0.1em 1em 0.01em rgb(255, 255, 255);
}

.pageDiv:hover * {
  /* transform: translate3d(150%); */
  font-size: 2.5rem;
}

.pageDivs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  position: absolute;
  bottom: 0.25em;
  right: 0;
  left: 0;
  margin: 0;
}

.gallery-cell {
  height: 95%;
  width: 85%;
}

.main-gallery {
  height: 75vh;
}
p {
  font-family: Roboto, sans-serif;
}

.intro {
  /* background: url('https://i.imgur.com/tuWuUws.jpg'); */
  /* background: url('https://i.imgur.com/JRvAPwN.jpg'); */
  height: 90vh;
  background-attachment: fixed;
  /* display: grid; */
  background-repeat: no-repeat;
  /* background-position: center; */
  background-size: contain;
  margin-top: 8vh;
}

img {
  max-width: -webkit-fill-available;
  max-width: stretch;
  width: auto;
  height: auto;
}

#about-div {
  scroll-margin-top: 8vh;
}

#resume-div {
  scroll-margin-top: 8vh;
}

#projects-gallery {
  scroll-margin-top: 8vh;
}

.about img {
  max-width: 40vw;
  height: auto;
}

.about h2 {
  font-size: 2rem;
  margin: 0.5rem 0 0 0;
}
.projectCardC a {
  font-size: 3rem;
  color: rgb(4, 0, 37);
}

.projectCardC a:hover {
  color: rgb(18, 2, 163);
}

.contactDetails {
  /* border: 0.1rem solid black; */
  padding: 1rem;
  margin: 0.5rem auto 1rem auto;
  border-radius: 1rem;
  max-width: 80vw;
  background-color: white;
  /* box-shadow: 0rem 0rem 1rem 0.01rem darkslateblue; */
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}
div {
  box-sizing: border-box;
}

article {
  padding: 1rem;
  margin: 1rem auto;
  border-radius: 1rem;
  max-width: 88vw;
  background-color: white;
  /* box-shadow: 0rem 0rem 1rem 0.1rem darkslateblue; */
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

#skillsBox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

#skillsBox2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  place-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  color: darkslateblue;
}

#skillsBox2 i {
  font-size: 3rem;
  color: black;
}

#skillsBox4 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  place-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}

#skillsBox4 i {
  font-size: 3.5rem;
}

#skillsBox2 div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 28px;
}

#skillsBox2 i {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 0.5rem;
}

article h1 {
  font-size: 3rem;
  margin: 0;
  text-align: left;
  /* margin-bottom: 1rem; */
  /* text-decoration: underline; */
}

.skill {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

svg {
  height: 5rem;
  max-height: 5rem;
  width: auto;
}

.contactDetails h1 {
  text-align: left;
  margin: 0;
  font-size: 3rem;
}

.contactDetails i {
  font-size: 2rem;
  padding: 0 1rem;
}

.contactInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.contactInfo div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  /* align-items: baseline;
  flex-direction: column; */
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.contactInfo div p {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  /* align-items: baseline;
  flex-direction: column; */
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.projects {
  /* display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.projects2 {
  display: grid;
  max-width: 80vw;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  /* display: flex; */
  /* flex-flow: row wrap;
  align-items: center;
  justify-content: center; */
}

.newpc {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: auto;
  background-size: cover;
  background-position: center;
  color: white;
  width: 77vw;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.newpc a {
  color: rgb(4, 0, 37);
}

.newpc h1 {
  color: rgb(4, 0, 37);
  font-size: 2rem;
}
.project-description {
  background-color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30%;
  padding: 0.5rem 1rem 0 1rem;

  text-align: center;
  color: rgb(4, 0, 37);
}
.newpc-title {
  background-color: white;
  position: absolute;
  top: 0;
  width: 100%;
  height: 15%;
  padding-top: 1rem;
  text-align: center;
  color: rgb(4, 0, 37);
}
.newpc-title h1 {
  /* background-color: rgb(4, 0, 37);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  padding-top: 1.5rem; */
  text-align: center;
}
/* @media screen and (max-width: 800px) {
  .sidebar {
    padding-top: 1rem;
  }
  .sidebar a {
    font-size: 1rem;
  }

  .myFooter {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
} */
#download-resume-button {
  display: none;
}
@media screen and (max-width: 450px) {
  .sidebar {
    padding-top: 1rem;
  }
  .sidebar a {
    font-size: 1rem;
  }

  #resume-pdf {
    display: none;
  }

  #download-resume-button {
    display: block;
    margin: 0 auto;
  }
}

/* #resume-pdf {
  display: in;
} */

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
/* @media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 1rem;
  }
  .sidebar a {
    font-size: 1rem;
  }
} */

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.line-1 {
  position: relative;
  top: 50%;
  width: 24em;
  margin: 0 auto;
  border-right: 2px solid rgba(255, 255, 255, 0.75);
  font-size: 180%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* Animation */
.anim-typewriter {
  -webkit-animation: typewriter 4s steps(44) 1s 1 normal;
          animation: typewriter 4s steps(44) 1s 1 normal;
}
@-webkit-keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 24em;
  }
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 24em;
  }
}
@-webkit-keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

@media screen and (max-width: 700px) {
  .hero {
    background-image: url('https://i.imgur.com/b37Ru89.jpg');
    background-position: center;
  }
}

/* .resumeHolder a {
  background-color: rgb(4, 0, 37);
}

.resumeHolder {
  border-radius: 10px;
} */

/* #resume-div {
  background-color: rgb(4, 0, 37);
} */

#resume-div button {
  background-color: rgb(4, 0, 37);
  border: transparent;
  border-radius: 1em;
  padding: 1em;
  color: white;
}

button a {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: white;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 3rem;
}

.hero .pageDiv {
  font-size: 2rem;
}

.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
    url('https://i.imgur.com/b37Ru89.jpg');
  height: 92vh;
  color: white;
  font-size: 3rem;
  text-align: left;
  padding: 2rem;
  position: relative;
  font-family: 'Bebas Neue', sans-serif;
  background-size: cover;
  max-width: 1000px;
  margin: 0 auto;
  border-radius: 0px;

  box-shadow: 0px 0px 5px #000000, 0px 0px 0.5px #000000;
}

.hero a {
  font-size: 2rem;
  color: white;
  text-decoration: none;
}

/* .fade-in-text {
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 5rem;
  color: white;
  animation: fadeIn linear 7s;
  -webkit-animation: fadeIn linear 7s;
  -moz-animation: fadeIn linear 7s;
  -o-animation: fadeIn linear 7s;
  -ms-animation: fadeIn linear 7s;
}

.fade-in-text2 p {
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 5rem;
  color: white;
  animation-delay: 2s;
  animation: fadeIn 2s linear 7s;
  -webkit-animation: fadeIn linear 7s;
  -moz-animation: fadeIn linear 7s;
  -o-animation: fadeIn linear 7s;
  -ms-animation: fadeIn linear 7s;
}

.fade-in-text3 p {
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 5rem;
  color: white;
  animation-delay: 8s;
  animation: fadeIn 4s linear 7s;
  -webkit-animation: fadeIn linear 7s;
  -moz-animation: fadeIn linear 7s;
  -o-animation: fadeIn linear 7s;
  -ms-animation: fadeIn linear 7s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

.animation-box {
  width: 100%;
  height: 27.5rem;
  /* background-color: blue; */
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.animation-container {
  width: 1000rem;
  height: 30rem;
}

@-webkit-keyframes topFadeOut {
  0% {
    position: absolute;
    top: -3rem;
    opacity: 0;
  }

  75% {
    position: absolute;
    top: 25%;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes topFadeOut {
  0% {
    position: absolute;
    top: -3rem;
    opacity: 0;
  }

  75% {
    position: absolute;
    top: 25%;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes bottomFadeOut {
  0% {
    position: absolute;
    bottom: -5rem;
    opacity: 0;
  }

  75% {
    position: absolute;
    bottom: 25%;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bottomFadeOut {
  0% {
    position: absolute;
    bottom: -5rem;
    opacity: 0;
  }

  75% {
    position: absolute;
    bottom: 25%;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes topFadeOutVertical {
  0% {
    position: absolute;
    top: -3rem;
    opacity: 0;
  }

  75% {
    position: absolute;
    top: 45%;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes topFadeOutVertical {
  0% {
    position: absolute;
    top: -3rem;
    opacity: 0;
  }

  75% {
    position: absolute;
    top: 45%;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes rightFadeInOut {
  0% {
    position: absolute;
    right: -3rem;
    opacity: 0;
  }

  75% {
    position: absolute;
    right: 10rem;
    opacity: 1;
  }

  100% {
    opacity: 1;
    /* right: 10rem; */
  }
}

@keyframes rightFadeInOut {
  0% {
    position: absolute;
    right: -3rem;
    opacity: 0;
  }

  75% {
    position: absolute;
    right: 10rem;
    opacity: 1;
  }

  100% {
    opacity: 1;
    /* right: 10rem; */
  }
}

@-webkit-keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  45% {
    opacity: 1;
  }

  100% {
    opacity: 0%;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  45% {
    opacity: 1;
  }

  100% {
    opacity: 0%;
  }
}

.first-text {
  font-size: 4.4rem;
  position: absolute;
  left: 2.5rem;
  top: 5rem;
  opacity: 0;
  -webkit-animation-name: topFadeOut;
          animation-name: topFadeOut;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  color: white;
}

.second-text {
  font-size: 3.4rem;
  position: absolute;
  top: 8.5rem;
  opacity: 0;
  -webkit-animation-name: rightFadeInOut;
          animation-name: rightFadeInOut;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  color: #ccc;
}

.third-text {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  font-size: 2rem;
  position: absolute;
  left: 45%;
  top: 45%;
  opacity: 0;
  -webkit-animation-name: topFadeOutVertical;
          animation-name: topFadeOutVertical;
  -webkit-animation-delay: 7.5s;
          animation-delay: 7.5s;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  color: #a3ddf0;
  white-space: nowrap;
}

.fourth-text {
  font-size: 3.4rem;
  position: absolute;
  top: 10.5rem;
  left: 10%;
  opacity: 0;
  color: white;
  -webkit-animation-name: fadeInOut;
          animation-name: fadeInOut;
  -webkit-animation-delay: 8.5s;
          animation-delay: 8.5s;
  -webkit-animation-duration: 9s;
          animation-duration: 9s;
}

.fifth-text {
  font-size: 5rem;
  position: absolute;
  left: 5%;
  bottom: 25%;
  opacity: 0;
  color: #f0eba3;
  -webkit-animation-name: bottomFadeOut;
          animation-name: bottomFadeOut;
  -webkit-animation-delay: 10s;
          animation-delay: 10s;
  -webkit-animation-duration: 9s;
          animation-duration: 9s;
}

/* defines the animation */
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* hero p {
  
  opacity: 0; 
  animation: fadeInUp 1s ease-in-out 0s forwards;
  &.first {
    animation-delay: 1s;
  }
  &.second {
    animation-delay: 3s;
  }
  &.third {
    animation-delay: 5s;
  }
  &.fourth {
    animation-delay: 7s;
  }
  &.fifth {
    animation-delay: 9s;
  }
  &.sixth {
    animation-delay: 11s;
  }
 } */

.hero .first {
  opacity: 0;
  -webkit-animation: fadeInUp 1s ease-in-out 0s forwards;
          animation: fadeInUp 1s ease-in-out 0s forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.hero .second {
  opacity: 0;
  -webkit-animation: fadeInUp 1s ease-in-out 0s forwards;
          animation: fadeInUp 1s ease-in-out 0s forwards;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.hero .third {
  opacity: 0;
  -webkit-animation: fadeInUp 1s ease-in-out 0s forwards;
          animation: fadeInUp 1s ease-in-out 0s forwards;
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
}

.hero p {
  margin: 0;
  font-family: 'Bebas Neue', 'Roboto', sans-serif;
}

.myHeader a {
  text-decoration: none;
}

#myFooter {
  width: 100%;
  height: 10vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  color: white;
  background-color: rgb(4, 0, 37);
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 1em 0 1em 0;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.icon-text-pair {
  display: -webkit-flex;
  display: flex;
  /* flex-direction: column; */
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  text-decoration: none;
  /* margin: 1em; */
  /* padding: 1em; */
  /* align-self: flex-end; */
}

a i {
  margin: 1em;
}

.icon-text-pair i {
  margin: 0 0.5em;
}

/* .icon-text-pair #envelope-fix {
  margin-top: -1px;
} */

.footer-text {
  font-family: 'Bebas Neue';
}

#icon-fixer {
  margin-top: -1px;
}

.icon-text-pair * {
  text-decoration: none;
  color: white;
}
/* i a {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.devicon-linkedin-plain a {
  width: 100%;
  height: 100%;
} */

